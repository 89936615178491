import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
} from "reactstrap";
import "./Login.css";
import TopNavBar from "../TopNavBar/TopNavBar";
import { faCoffee, faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoginGraphic from "../../assets/login-graphic.png";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useUser } from "../../UserContext"; // Adjust the path as needed

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [feedback, setFeedback] = useState("");
  const [accountType, setAccountType] = useState(null);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const { endpoint } = useUser(); // Access the setUser function
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!privacyPolicy) {
      toast.error("Please agree to the privacy policy and terms of service.");
      setFeedback("Please agree to the privacy policy and terms of service.");
      return;
    }
    try {
      const response = await fetch(`${endpoint}/api/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password, accountType }),
      });

      const data = await response.json();

      if (response.status === 201) {
        setFeedback("Signup successful. Please log in.");
        toast.success("Signup successful. Please log in.");
        setTimeout(() => {
          navigate("/login");
        }, 3000);
        // Redirect to login page or dashboard as necessary
      } else {
        throw new Error(data.message || "An error occurred during signup.");
      }
    } catch (error) {
      toast.error(error.message);
      setFeedback(error.message);
    }
  };

  return (
    <>
      <TopNavBar />
      <Container className="login-ui">
        <Row>
          <Col md="6" className="login-graphic-container">
            <img
              src={LoginGraphic}
              alt="Login Graphic"
              className="login-graphic"
            />
          </Col>
          <Col md="6">
            <div className="login-container">
              <div className="login-header">
                Let's get started! <FontAwesomeIcon icon={faRocket} />
              </div>
              <div className="login-subheader">
                Enter your email and password to sign up
              </div>
              <Form onSubmit={handleSignup}>
                <FormGroup>
                  <Label for="accountType">Account Type</Label>
                  <Input
                    type="select"
                    name="accountType"
                    id="accountType"
                    value={accountType}
                    onChange={(e) => setAccountType(e.target.value)}
                  >
                    <option value="">Select account type</option>
                    <option value="notary">Notary</option>
                    <option value="customer">Signing Service</option>
                    <option value="realestate-agent">Real Estate Agent</option>
                    <option value="title-company">Title Company</option>
                    <option value="lender">Lender</option>
                    <option value="other">Other</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormGroup>

                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" onChange={
                      (e)=>{
                        setPrivacyPolicy(e.target.checked)
                      }
                    } /> I agree to both the{" "}
                    <a
                      href="https://app.termly.io/document/privacy-policy/dcd04f13-a3e6-47e3-9213-405f663c31cb"
                      target="_blank"
                    >
                      Privacy Policy
                    </a> 
                    {" "} and{" "}
                    <a
                      href="https://app.termly.io/document/terms-of-service/4d179bd4-7d85-4129-8dcf-9fc24af3debc"
                      target="_blank"
                    >
                      Terms of Service
                    </a>
                  </Label>
                </FormGroup>
                <Button
                  style={{
                    background: "#665942",
                    color: "white",
                    border: 0,
                  }}
                >
                  Sign Up
                </Button>
              </Form>
              {feedback && <div className="feedback">{feedback}</div>}
              <div className="signup-option">
                Already have an account? <a href="/login">Login</a>
              </div>
            </div>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}

export default Signup;

import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="social-icons">
            <a
              href="https://www.instagram.com/coffeeshopnotaries/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://www.facebook.com/CoffeeShopNotaries"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              href="https://www.youtube.com/channel/UCeBd11Z5JBCq12-6P2N8TeA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <a
              href="https://www.tiktok.com/@coffeeshopnotaries?_t=8kvTHTKgfUd&_r=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTiktok} />
            </a>
            <a
              href="https://linktr.ee/coffeeshopnotaries"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLink} />
            </a>
            <a
              href="https://sites.google.com/vipnotary.co/coffeeshopnotariesshop/home_1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Shop
            </a>
            <a
              href="https://coffeeshopnotaries.etsy.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              ETSY
            </a>
          </div>
          <p>
            &copy; {new Date().getFullYear()} Coffee Shop Notary. All rights
            reserved. | Software created by{" "}
            <a
              href="https://www.companionyou.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Companion You
            </a>
          </p>
          <p>
            <a
              href="https://app.termly.io/document/privacy-policy/dcd04f13-a3e6-47e3-9213-405f663c31cb"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{" "}
            |{" "}
            <a
              href="https://app.termly.io/document/terms-of-service/4d179bd4-7d85-4129-8dcf-9fc24af3debc"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import TopNavBar from "../TopNavBar/TopNavBar";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useUser } from "../../UserContext";
function ResetPasswordPage() {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [feedback, setFeedback] = useState("");
  const { endpoint } = useUser();
  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setFeedback("Passwords do not match.");
      return;
    }

    try {
      const response = await fetch(
        `${endpoint}/api/auth/reset-password/${token}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ password }),
        }
      );

      const data = await response.json();
      if (response.status === 200) {
        setFeedback("Password reset successfully.");
        toast.success("Password reset successfully.");
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
      } else {
        throw new Error(
          data.message || "An error occurred during password reset."
        );
      }
    } catch (error) {
      setFeedback(error.message);
      toast.error(error.message);
    }
  };

  return (
    <>
      <TopNavBar />
      <Container className="mt-5">
        <Row>
          <Col md="6" className="offset-md-3">
            <h2>Reset Password</h2>
            <Form onSubmit={handleResetPassword}>
              <FormGroup>
                <Label for="password">New Password</Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter your new password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="confirmPassword">Confirm Password</Label>
                <Input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="Confirm your new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </FormGroup>
              <Button
                style={{
                  background: "#665942",
                  color: "white",
                  border: 0,
                }}
              >
                Reset Password
              </Button>
            </Form>
            {feedback && <div className="feedback">{feedback}</div>}
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}

export default ResetPasswordPage;

import React, { useState } from "react";
import {
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
  Row,
  Col,
} from "reactstrap";
import TopNavBar from "../TopNavBar/TopNavBar";
import Footer from "../Footer/Footer";
import { toast, ToastContainer } from "react-toastify";
import { useUser } from "../../UserContext";
function Contact() {
  const [formData, setFormData] = useState({
    name: null,
    email: null,
    message: null,
  });
  const [status, setStatus] = useState("");
  const { endpoint } = useUser();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Update the endpoint to your backend route
    const backendEndpoint = "/api/contact";

    try {
      const response = await fetch(`${endpoint}${backendEndpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <TopNavBar />
      <Container>
        <br />
        <br />
        <h2>Contact Us</h2>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="message">Message</Label>
            <Input
              type="textarea"
              name="message"
              id="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <Button
            type="submit"
            style={{
              background: "#665942",
              color:"white",
              border: 0
            }}
            disabed={!formData.name || !formData.email || !formData.message}
          >
            Send
          </Button>
        </Form>
        {status === "success" && (
          <Alert color="success">Message sent successfully!</Alert>
        )}
        {status === "error" && (
          <Alert color="danger">
            Failed to send message. Please try again.
          </Alert>
        )}
      </Container>
      <Row
        style={{
          position: "absolute",
          bottom: "0",
          width: "100%",
        }}
      >
        <Col md="12" className="text-center">
          <Footer />
        </Col>
      </Row>
      <ToastContainer />
    </>
  );
}

export default Contact;

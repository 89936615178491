import React, { useState } from "react";
import { Container, Row, Col, Input, Button, FormGroup } from "reactstrap";
import { InputGroup, InputGroupText } from "reactstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import TopNavBar from "../TopNavBar/TopNavBar.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import FAQs from "./FAQs.js";
import FeaturesList from "./FeaturesList.js";
import "./SearchLandingPage.css";
import Footer from "../Footer/Footer.js";
import HeroMan from "../../assets/hero-man.png";
import HeroWoman from "../../assets/hero-woman.png";
import {toast, ToastContainer} from "react-toastify";
function SearchLandingPage() {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate(); // Use the useNavigate hook

  const handleSearch = () => {
    if (!searchQuery) {
      toast.error("Please enter a search query");
      return;
    }
    // Navigate to the search route with the search query as a parameter
    navigate(`/search/${encodeURIComponent(searchQuery)}`);
  };

  return (
    <div style={{
      width: "100vw",
    }}>
      <TopNavBar />
      <img
        src={HeroMan}
        alt="Left Image"
        className="fixed-left-image"
      />
      <img
        src={HeroWoman}
        alt="Right Image"
        className="fixed-right-image"
      />
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col md="6">
            <div className="header-main">
              Find Your{" "}
              <span
                style={{
                  // color: "#665A42",
                  color: "#9D8B68"
                }}
              >
                Notary
              </span>
            </div>
            <div className="header-sub">
              Search for a notary by city, state or zip code
            </div>
            <br />
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSearch();
              }}
            >
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Enter a city, state, or zipcode"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="search-input"
                />
                <InputGroupText style={{
                  borderRadius: "0px 38px 38px 0px",
                }}>
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{
                      color: "black",
                      fontSize: "1.5em",
                      width: "50px",
                      verticalAlign: "middle",
                      borderRadius: "0px 38px 38px 0px",
                      cursor: "pointer",
                    }}
                    onClick={handleSearch}
                  />
                </InputGroupText>
              </InputGroup>
            </form>
            <br />
            <br />
            <br />
            <FeaturesList />
            <br />
            <br />
            <FAQs />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col md="12" className="text-center">
            <Footer />
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </div>
  );
}

export default SearchLandingPage;

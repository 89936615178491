// src/components/Home.js
import React from 'react';

function NotFound() {
  return (
    <div>
      <h1>404: Not Found</h1>
    </div>
  );
}

export default NotFound;

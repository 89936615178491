// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAR3z0nycwuPAehzar-7R2aAiou_96iZiw",
  authDomain: "coffeeshopnotaries.firebaseapp.com",
  projectId: "coffeeshopnotaries",
  storageBucket: "coffeeshopnotaries.appspot.com",
  messagingSenderId: "1094544210317",
  appId: "1:1094544210317:web:5f5127cb048e26a62965c4",
  measurementId: "G-BTT3MRZ0RN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Storage
const storage = getStorage(app);

export default storage;

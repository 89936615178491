// src/components/Home.js
import React from 'react';

function About() {
  return (
    <div>
      <h1>Welcome to the About Page</h1>
    </div>
  );
}

export default About;

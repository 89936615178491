import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  FormGroup,
  Card,
  CardBody,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCoffee,
  faInfo,
  faInfoCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import ProfilePlaceholder from "../../assets/profile-placeholder.png";
import ProfilePlaceholder from "../../assets/profile-pic-placeholder.png";
import LSSAffiliateAd from "../../assets/ad-1.png";
import Ad2 from "../../assets/ad-2.png";
import Ad3 from "../../assets/ad-3.png";
import Ad4 from "../../assets/ad-4.png";
import Ad5 from "../../assets/ad-5.png";
import "./SearchResults.css";
import TopNavBar from "../TopNavBar/TopNavBar";
import Footer from "../Footer/Footer";
import { useUser } from "../../UserContext";
const allServices = [
  "Apostille",
  "Boots on the Ground",
  "Comercial Signings",
  "Document Preparation",
  "Field Inspector",
  "General Notary Work",
  "HELOC Signing",
  "Investor Signings",
  "Loan Modification",
  "Permit Runner",
  "Refinance Signing",
  "Reverse Mortgage",
  "RON (Remote Online Notarization)",
  "Witness Only",
];

const ads = [
  {
    img: LSSAffiliateAd,
    link: "https://training.loansigningsystem.com/lizeth-nevada-free-gnw-training/",
  },
  {
    img: Ad2,
    link: "https://sites.google.com/vipnotary.co/coffeeshopnotariesshop/notary-journals?authuser=0",
  },
  {
    img: Ad3,
    link: "https://linktr.ee/coffeeshopnotaries",
  },
  {
    img: Ad4,
    link: "https://www.fundandgrow.com/lizethgalvanwebinar/",
  },
  {
    img: Ad5,
    link: "https://lets.bankwithrelay.com/voyami/",
  },
];
function SearchResults() {
  let { query } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [notaries, setNotaries] = useState([]);
  const [search, setSearch] = useState(query || "");
  const [selectedService, setSelectedService] = useState("");
  const [selectedEducation, setSelectedEducation] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedCertification, setSelectedCertification] = useState("");
  const [adIndex, setAdIndex] = useState(
    Math.floor(Math.random() * ads.length)
  );
  const navigate = useNavigate();
  const { endpoint } = useUser();

  useEffect(() => {
    const fetchNotaries = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${endpoint}/api/notaries/search?query=${query}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch notaries");
        }
        const data = await response.json();
        setNotaries(data.notaries);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNotaries();
  }, [query]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearch = () => {
    if (search === query) return;
    setNotaries([]);
    navigate(`/search/${search || "near me"}`);
  };

  const handleServiceChange = (e) => {
    setSelectedService(e.target.value);
  };

  const handleCertificationChange = (e) => {
    setSelectedCertification(e.target.value);
  };

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const handleEducationChange = (e) => {
    setSelectedEducation(e.target.value);
  };

  const filteredNotaries = notaries.filter((notary) => {
    return (
      (selectedService === "" || notary.services?.includes(selectedService)) &&
      (selectedCertification === "" ||
        notary.certifications?.includes(selectedCertification)) &&
      (selectedLanguage === "" || notary.languages?.includes(selectedLanguage))
    );
  });

  function formatPhoneNumber(phoneNumber) {
    // Remove any non-digit characters from the phone number
    const cleaned = phoneNumber.replace(/\D/g, "");

    // Format the phone number as (XXX) XXX-XXXX
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    // Return the original phone number if formatting is not possible
    return phoneNumber;
  }

  return (
    <>
      <TopNavBar />
      <Container className="mt-5" style={{ marginBottom: "100px" }}>
        <Row>
          <Col>
            <h2 className="search-results-heading">
              Search Results for Notaries in{" "}
              <span
                style={{
                  color: "#665942",
                  border: 0,
                }}
              >
                {query}
              </span>
            </h2>
          </Col>
        </Row>
        <Row>
          {/* Search input */}
          <Col md="8">
            <Input
              type="text"
              placeholder="Search for notaries"
              className="mini-search-input"
              onChange={handleSearchChange}
              value={search}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                } else if (e.key === "Escape") {
                  setSearch("");
                }
              }}
            />
          </Col>
          {/* Filters */}
          <Col md="4">
            <FormGroup
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Input
                type="select"
                name="services"
                id="services"
                value={selectedService}
                onChange={handleServiceChange}
              >
                <option value="">Services</option>
                {allServices.map((service, index) => (
                  <option key={index} value={service}>
                    {service}
                  </option>
                ))}
              </Input>
              <Input
                type="select"
                name="certifications"
                id="certifications"
                value={selectedCertification}
                onChange={(e) => {
                  handleCertificationChange(e);
                }}
              >
                {[
                  "Certifications",
                  "Certified Notary Public",
                  "Certified Translator",
                  "Apostille Specialist",
                  "Loan Signing Agent",
                  "LSS Certified",
                  "NNA Certified",
                  "Notary2Pro Certified",
                  // Add more certifications as needed
                ].map((certification, index) => (
                  <option
                    key={index}
                    value={
                      certification === "Certifications" ? "" : certification
                    }
                  >
                    {certification}
                  </option>
                ))}
              </Input>
              <Input
                type="select"
                name="languages"
                id="languages"
                value={selectedLanguage}
                onChange={handleLanguageChange}
              >
                <option value="">Languages</option>
                {[
                  "Spanish",
                  "French",
                  "German",
                  "Italian",
                  "Chinese",
                  "Japanese",
                  "Korean",
                  "Arabic",
                  "Russian",
                  "Portuguese",
                  "Vietnamese",
                  "Hindi",
                  "Urdu",
                  "Turkish",
                  "Dutch",
                  "Polish",
                  "Greek",
                  "Swedish",
                  "Norwegian",
                  "Danish",
                  "Finnish",
                  "Hebrew",
                  "Thai",
                  "Indonesian",
                  "Malay",
                  "Tagalog",
                  "Swahili",
                  "Yoruba",
                  "Igbo",
                  "Hausa",
                  "Zulu",
                  "Xhosa",
                  "Amharic",
                  "Somali",
                  "Tigrinya",
                  "Oromo",
                  "Kinyarwanda",
                  "Kirundi",
                ].map((language, index) => (
                  <option key={index} value={language}>
                    {language}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex justify-content-center">
            <img
              src={ads[adIndex].img}
              alt="Ad"
              className="ad-720x90"
              onClick={() => {
                window.open(ads[adIndex].link, "_blank");
              }}
            />
          </Col>
        </Row>

        {/* Notaries list */}
        <Row className="mt-4">
          {/* Loading and no results messages */}
          {isLoading && (
            <Col className="d-flex flex-column align-items-center justify-content-center">
              <p className="text-center">Loading notaries...</p>
              <FontAwesomeIcon icon={faCoffee} size="3x" />
            </Col>
          )}
          {!isLoading && filteredNotaries.length === 0 && (
            <Col>
              <p className="text-center">No notaries found</p>
            </Col>
          )}
          {!isLoading && filteredNotaries.length > 0 && (
            <div style={{ marginBottom: "20px" }}>
              <b>{filteredNotaries.length}</b> notaries found
            </div>
          )}

          {/* Notaries cards */}
          {!isLoading &&
            filteredNotaries.length > 0 &&
            filteredNotaries.map((notary) => (
              <Col
                key={notary.user_id || Math.random()}
                md="12"
                className="mb-3"
              >
                <Card className="notary-card">
                  <CardBody>
                    <Row>
                      <Col md="2" className="d-flex justify-content-center">
                        <img
                          src={notary.profile_pic || ProfilePlaceholder}
                          alt="Profile"
                          className="profile-pic"
                        />
                      </Col>
                      <Col md="4">
                        <h5 className="notary-name">{notary.name}</h5>
                        <p className="text-muted">
                          {notary.business_name || ""}
                        </p>
                        <p className="text-muted">
                          {notary.city}, {notary.state}
                        </p>
                        <div className="services-pills">
                          {!notary.services && (
                            <span
                              className="text-muted"
                              style={{
                                fontSize: "0.8rem",
                              }}
                            >
                              No services offered
                            </span>
                          )}
                          {notary.services?.map((service, index) => (
                            <Button
                              key={index}
                              color="primary"
                              size="sm"
                              className="me-1"
                            >
                              {service}
                            </Button>
                          ))}
                        </div>
                        {!notary.user_id && (
                          <p
                            className="text-muted"
                            style={{ fontSize: "0.8rem" }}
                          >
                            Note: This notary is not a verified member of Coffee
                            Shop Notaries
                          </p>
                        )}
                        {notary.user_id && (
                          <p
                            className="text-success"
                            style={{ fontSize: "0.8rem" }}
                          >
                            <FontAwesomeIcon icon={faCheckCircle} /> Verified
                            member of Coffee Shop Notaries
                          </p>
                        )}
                      </Col>
                      <Col md="4">
                        <p className="notary-contact">
                          <strong>Email:</strong> {notary.email}
                        </p>
                        {notary.phone_number && (
                          <p className="notary-contact">
                            <strong>Phone:</strong>{" "}
                            <a
                              href={`tel:${notary.phone_number}`}
                              style={{
                                color: "#665942",
                              }}
                            >
                              {formatPhoneNumber(notary.phone_number)}
                            </a>
                          </p>
                        )}
                        <div className="services-pills notary-contact">
                          <b>Certifications:</b>
                          {notary.certifications?.length === 0 && (
                            <span className="text-muted">None</span>
                          )}
                          {notary.certifications?.map(
                            (certification, index) => (
                              <>
                                {certification}
                                {index < notary.certifications.length - 1
                                  ? ", "
                                  : ""}
                              </>
                            )
                          )}
                        </div>
                      </Col>
                      <Col
                        md="2"
                        className="d-flex align-items-center justify-content-end"
                      >
                        <Button
                          onClick={() => navigate(`/notaries/${notary.id}`)}
                          style={{
                            background: "#665942",
                            color: "white",
                            border: 0,
                          }}
                        >
                          View Profile
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))}
        </Row>
      </Container>
    </>
  );
}

export default SearchResults;

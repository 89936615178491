import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
} from "reactstrap";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUser } from "../../UserContext"; // Import useUser hook
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  faEarth,
  faExternalLink,
  faPlug,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { ref } from "firebase/storage";
import storage from "../../firebase-config";
import { uploadBytes, getDownloadURL } from "firebase/storage";

// Create a reference to a file location
function EditNotaryProfile() {
  const { user, endpoint } = useUser(); // Access the user data
  const [notaryProfileCreated, setNotaryProfileCreated] = useState(false);
  const [bioCharCount, setBioCharCount] = useState(0);
  const navigate = useNavigate();
  const allServices = [
    "Apostille",
    "Boots on the Ground",
    "Comercial Signings",
    "Document Preparation",
    "Field Inspector",
    "General Notary Work",
    "HELOC Signing",
    "Investor Signings",
    "Loan Modification",
    "Permit Runner",
    "Refinance Signing",
    "Reverse Mortgage",
    "RON (Remote Online Notarization)",
    "Witness Only",
  ];

  const [notaryProfile, setNotaryProfile] = useState({
    name: "",
    location: "",
    services: [],
    profile_pic: "",
    banner_img: "",
    description: "",
    profile_link: "",
    education: [],
    about: "",
    email: "",
    phone_number: "",
    languages: "",
    // Add more fields as needed
  });

  const [socialMediaLinks, setSocialMediaLinks] = useState({
    facebook: "",
    twitter: "",
    instagram: "",
  });

  const states = [
    { name: "Alabama", abbreviation: "AL" },
    { name: "Alaska", abbreviation: "AK" },
    { name: "Arizona", abbreviation: "AZ" },
    { name: "Arkansas", abbreviation: "AR" },
    { name: "California", abbreviation: "CA" },
    { name: "Colorado", abbreviation: "CO" },
    { name: "Connecticut", abbreviation: "CT" },
    { name: "Delaware", abbreviation: "DE" },
    { name: "Florida", abbreviation: "FL" },
    { name: "Georgia", abbreviation: "GA" },
    { name: "Hawaii", abbreviation: "HI" },
    { name: "Idaho", abbreviation: "ID" },
    { name: "Illinois", abbreviation: "IL" },
    { name: "Indiana", abbreviation: "IN" },
    { name: "Iowa", abbreviation: "IA" },
    { name: "Kansas", abbreviation: "KS" },
    { name: "Kentucky", abbreviation: "KY" },
    { name: "Louisiana", abbreviation: "LA" },
    { name: "Maine", abbreviation: "ME" },
    { name: "Maryland", abbreviation: "MD" },
    { name: "Massachusetts", abbreviation: "MA" },
    { name: "Michigan", abbreviation: "MI" },
    { name: "Minnesota", abbreviation: "MN" },
    { name: "Mississippi", abbreviation: "MS" },
    { name: "Missouri", abbreviation: "MO" },
    { name: "Montana", abbreviation: "MT" },
    { name: "Nebraska", abbreviation: "NE" },
    { name: "Nevada", abbreviation: "NV" },
    { name: "New Hampshire", abbreviation: "NH" },
    { name: "New Jersey", abbreviation: "NJ" },
    { name: "New Mexico", abbreviation: "NM" },
    { name: "New York", abbreviation: "NY" },
    { name: "North Carolina", abbreviation: "NC" },
    { name: "North Dakota", abbreviation: "ND" },
    { name: "Ohio", abbreviation: "OH" },
    { name: "Oklahoma", abbreviation: "OK" },
    { name: "Oregon", abbreviation: "OR" },
    { name: "Pennsylvania", abbreviation: "PA" },
    { name: "Rhode Island", abbreviation: "RI" },
    { name: "South Carolina", abbreviation: "SC" },
    { name: "South Dakota", abbreviation: "SD" },
    { name: "Tennessee", abbreviation: "TN" },
    { name: "Texas", abbreviation: "TX" },
    { name: "Utah", abbreviation: "UT" },
    { name: "Vermont", abbreviation: "VT" },
    { name: "Virginia", abbreviation: "VA" },
    { name: "Washington", abbreviation: "WA" },
    { name: "West Virginia", abbreviation: "WV" },
    { name: "Wisconsin", abbreviation: "WI" },
    { name: "Wyoming", abbreviation: "WY" },
  ];

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `profile_pictures/${file.name}`);
    await uploadBytes(storageRef, file);
    const fileUrl = await getDownloadURL(storageRef);
    console.log("Setting profile pic url", fileUrl);
    setNotaryProfile({ ...notaryProfile, profile_pic: fileUrl });
  };

  const allCertifications = [
    "Certified Notary Public",
    "Certified Translator",
    "Apostille Specialist",
    "Loan Signing Agent",
    "LSS Certified",
    "NNA Certified",
    "Notary2Pro Certified",
    // Add more certifications as needed
  ];

  useEffect(() => {
    // Fetch notary profile data from the backend
    const fetchNotaryProfile = async () => {
      try {
        const response = await fetch(`${endpoint}/api/notaries/${user.id}`);
        if (!response.ok) {
          if (
            response.status === 404 &&
            response.statusText === "Notary not found"
          ) {
            setNotaryProfileCreated(false);
          } else {
            throw new Error("Failed to fetch notary profile");
          }
        }
        setNotaryProfileCreated(true);
        const data = await response.json();
        console.log("Notary profile found", data);

        setNotaryProfile(data);
      } catch (error) {
        console.error(error);
      }
    };

    if (user) {
      fetchNotaryProfile();
    }
  }, [user]);

  const handleAddEducation = () => {
    if (!notaryProfile.education) {
      setNotaryProfile({ ...notaryProfile, education: [] });
    } else {
      setNotaryProfile((prevProfile) => ({
        ...prevProfile,
        education: [...prevProfile.education, ""], // Add an empty string to the array
      }));
    }
  };

  const handleEducationChange = (index, value) => {
    if (!notaryProfile.education) {
      setNotaryProfile({ ...notaryProfile, education: [] });
    }
    setNotaryProfile((prevProfile) => ({
      ...prevProfile,
      education: prevProfile.education.map((education, i) =>
        i === index ? value : education
      ),
    }));
  };

  const handleRemoveEducation = (index) => {
    setNotaryProfile((prevProfile) => ({
      ...prevProfile,
      education: prevProfile.education.filter((_, i) => i !== index),
    }));
  };

  const handleChange = (e) => {
    if (e.target.name === "bio") {
      const bio = e.target.value;
      if (bio.length <= 150) {
        setNotaryProfile({ ...notaryProfile, bio });
        setBioCharCount(bio.length);
      }
    } else {
      setNotaryProfile({ ...notaryProfile, [e.target.name]: e.target.value });
    }
  };

  const handleCertificationChange = (e) => {
    const certification = e.target.value;
    const isChecked = e.target.checked;
    if (!notaryProfile.certifications) {
      setNotaryProfile({ ...notaryProfile, certifications: [] });
    }
    setNotaryProfile((prevProfile) => ({
      ...prevProfile,
      certifications: isChecked
        ? [...prevProfile.certifications, certification]
        : prevProfile.certifications.filter((c) => c !== certification),
    }));
  };

  const handleServiceChange = (e) => {
    const service = e.target.value;
    const isChecked = e.target.checked;
    if (!notaryProfile.services) {
      setNotaryProfile({ ...notaryProfile, services: [] });
    }
    setNotaryProfile((prevProfile) => ({
      ...prevProfile,
      services: isChecked
        ? [...prevProfile.services, service]
        : prevProfile.services.filter((s) => s !== service),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Update notary profile data in the backend
    try {
      console.log("Updating notary profile", notaryProfile);
      if (typeof notaryProfile.zip_codes === "string") {
        notaryProfile.zip_codes = notaryProfile.zip_codes
          .split(",")
          .map((zip) => zip.trim());
      } else if (!Array.isArray(notaryProfile.zip_codes)) {
        notaryProfile.zip_codes = [];
      }
      const response = await fetch(`${endpoint}/api/notaries/${user.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(notaryProfile),
      });

      if (!response.ok) {
        throw new Error("Failed to update notary profile");
      }

      toast.success("Profile updated successfully!");
    } catch (error) {
      console.error(error);
      toast.error("Error updating profile");
    }
  };

  if (!notaryProfileCreated) {
    return (
      <Container>
        <Row>
          <Col>
            <span>
              <h3>Notary Profile</h3>
              <p>You have not created a notary profile yet</p>
              <Button
                style={{
                  background: "#665942",
                  color: "white",
                  border: 0,
                }}
                onClick={() => {
                  navigate("/account-setup");
                }}
              >
                Create a Notary Profile
              </Button>
            </span>
          </Col>
        </Row>
      </Container>
    );
  }
  return (
    <Container>
      <Row>
        <Col>
          <h3>Edit Notary Profile</h3>

          <Button
            style={{
              background: "#665942",
              color: "white",
              border: 0,
              marginBottom: "20px",
            }}
            onClick={() => {
              navigate(`/notaries/${notaryProfile.id}`);
            }}
          >
            View Profile <FontAwesomeIcon icon={faExternalLink} />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <span>
                <Label for="profile_pic">Profile Picture</Label>
                <br />
                {notaryProfile.profile_pic && (
                  <>
                    <img
                      src={notaryProfile.profile_pic}
                      alt="Profile"
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                      }}
                    />
                  </>
                )}
                <span>
                  <Input
                    type="file"
                    name="profile_pic"
                    id="profile_pic"
                    onChange={handleFileUpload}
                  />
                </span>
              </span>
            </FormGroup>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={notaryProfile.name}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={notaryProfile.email}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="business_name">Business Name</Label>
              <Input
                type="text"
                name="business_name"
                id="business_name"
                value={notaryProfile.business_name}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="name">Bio</Label>
              <p style={{ fontSize: "0.8rem", color: "grey" }}>
                Write a short bio about yourself
              </p>
              <Input
                type="textarea"
                name="bio"
                id="bio"
                value={notaryProfile.bio}
                onChange={handleChange}
                maxLength={150}
              />
              <p style={{ fontSize: "0.8rem", color: "grey" }}>
                {bioCharCount}/150 characters
              </p>
            </FormGroup>
            <FormGroup>
              <Label for="city">City</Label>
              <Input
                type="text"
                name="city"
                id="city"
                value={notaryProfile.city}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="state">State</Label>
              <Input
                type="select"
                name="state"
                id="state"
                value={notaryProfile.state}
                onChange={handleChange}
              >
                <option value="">Select a state</option>
                {states.map((state) => (
                  <option key={state.abbreviation} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </Input>
            </FormGroup>

            <FormGroup>
              <FormGroup>
                <Label>Services</Label>
                {allServices.map((service) => (
                  <FormGroup check key={service}>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="services"
                        value={service}
                        checked={notaryProfile.services?.includes(service)}
                        onChange={handleServiceChange}
                      />
                      {service}
                    </Label>
                  </FormGroup>
                ))}
              </FormGroup>
            </FormGroup>
            <FormGroup>
              <Label>Certifications</Label>
              {allCertifications.map((certification) => (
                <FormGroup check key={certification}>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="certifications"
                      value={certification}
                      checked={notaryProfile.certifications?.includes(
                        certification
                      )}
                      onChange={handleCertificationChange}
                    />
                    {certification}
                  </Label>
                </FormGroup>
              ))}
            </FormGroup>

            <FormGroup>
              <Label for="language">Language</Label>
              <p
                style={{
                  fontSize: "0.8rem",
                  color: "grey",
                }}
              >
                What language do you speak? Separate multiple languages with a
                comma
              </p>
              <Input
                type="text"
                name="languages"
                placeholder="Ex: English, Spanish, French"
                id="languages"
                value={notaryProfile.languages}
                onChange={handleChange}
              />
            </FormGroup>

            {/* <FormGroup>
              <Label for="banner_color">Banner Color</Label>
              <Input
                type="color"
                name="banner_color"
                id="banner_color"
                style={{ width: "100px", height: "50px" }}
                value={notaryProfile.banner_color}
                onChange={handleChange}
              />
            </FormGroup> */}
            <FormGroup>
              <Label for="phone_number">Phone Number</Label>
              <Input
                type="text"
                name="phone_number"
                id="phone_number"
                value={notaryProfile.phone_number}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="zip_codes">Zip Codes</Label>
              <p
                style={{
                  fontSize: "0.8rem",
                  color: "grey",
                }}
              >
                Enter a comma separated list of zip codes you serve
              </p>
              <Input
                type="textarea"
                name="zip_codes"
                id="zip_codes"
                value={notaryProfile.zip_codes}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">About Me</Label>
              <Input
                type="textarea"
                name="description"
                id="description"
                value={notaryProfile.description}
                onChange={handleChange}
              />
            </FormGroup>
            {/* Lizeth asked to disabled education section */}
            {/* <FormGroup>
              <Label for="education">Education</Label>
              <br />
              {notaryProfile.education?.map((education, index) => (
                <div key={index} className="mb-2">
                  <Input
                    type="text"
                    placeholder="Ex: Bachelor's in Law from XYZ University"
                    name={`education-${index}`}
                    value={education}
                    onChange={(e) =>
                      handleEducationChange(index, e.target.value)
                    }
                  />
                  <Button
                    color="danger"
                    size="sm"
                    className="mt-2"
                    onClick={() => handleRemoveEducation(index)}
                  >
                    Remove
                  </Button>
                </div>
              ))}
              <Button
                style={{
                  background: "#665942",
                  color: "white",
                  border: 0,
                }}
                size="sm"
                onClick={handleAddEducation}
              >
                <FontAwesomeIcon icon={faPlus} />
                Add Education
              </Button>
            </FormGroup> */}
            <FormGroup>
              <Label for="video_link">Intro Link</Label>
              <p
                style={{
                  fontSize: "0.8rem",
                  color: "grey",
                }}
              >
                Add a youtube video link introducing yourself to potential
                clients
              </p>
              <Input
                type="text"
                name="video_link"
                id="video_link"
                placeholder="https://www.youtube.com/watch?v=example"
                value={notaryProfile.video_link}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="google_my_business_link">
                Google My Business Review Link
              </Label>
              <p
                style={{
                  fontSize: "0.8rem",
                  color: "grey",
                }}
              >
                Add your google my business link or direct link to your google
                review page
              </p>
              <Input
                type="text"
                name="google_my_business_link"
                id="google_my_business_link"
                placeholder="https://www.google.com/search?q=example"
                value={notaryProfile.google_my_business_link}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="facebook">Socials</Label>
              <InputGroup>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 30,
                    marginRight: 5,
                    color: "blue",
                  }}
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </div>
                <Input
                  type="text"
                  name="facebook_link"
                  id="facebook_link"
                  placeholder="https://www.facebook.com/example"
                  value={notaryProfile.facebook_link}
                  onChange={handleChange}
                />
              </InputGroup>
              <br />
              <InputGroup>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 30,
                    marginRight: 5,
                    color: "lightblue",
                  }}
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </div>
                <Input
                  type="text"
                  name="twitter_link"
                  id="twitter"
                  placeholder="https://www.twitter.com/example"
                  value={notaryProfile.twitter_link}
                  onChange={handleChange}
                />
              </InputGroup>
              <br />
              <InputGroup>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 30,
                    marginRight: 5,
                    color: "purple",
                  }}
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </div>
                <Input
                  type="text"
                  name="instagram_link"
                  id="instagram"
                  placeholder="https://www.instagram.com/example"
                  value={notaryProfile.instagram_link}
                  onChange={handleChange}
                />
              </InputGroup>
              <br />
              <InputGroup>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 30,
                    marginRight: 5,
                    color: "blue",
                  }}
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                </div>
                <Input
                  type="text"
                  name="linkedin_link"
                  id="linkedin"
                  placeholder="https://www.linkedin.com/example"
                  value={notaryProfile.linkedin_link}
                  onChange={handleChange}
                />
              </InputGroup>
              <br />
              <InputGroup>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 30,
                    marginRight: 5,
                    color: "grey",
                  }}
                >
                  <FontAwesomeIcon icon={faEarth} />
                </div>
                <Input
                  type="text"
                  name="website_link"
                  id="website_link"
                  placeholder="https://www.example.com"
                  value={notaryProfile.website_link}
                  onChange={handleChange}
                />
              </InputGroup>
            </FormGroup>

            {/* Repeat for other social media platforms */}

            {/* Add more form groups as needed */}
            <Button
              style={{
                background: "#665942",
                color: "white",
                border: 0,
              }}
            >
              Update Profile
            </Button>
          </Form>

          <br />
          <br />
          <br />
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
}

export default EditNotaryProfile;

import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
} from "reactstrap";
import TopNavBar from "../TopNavBar/TopNavBar";
import "./NotaryProfile.css";
import {
  faCheck,
  faCheckCircle,
  faCircleDot,
  faCopy,
  faDotCircle,
  faEnvelope,
  faGlobe,
  faMessage,
  faPhone,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
// import ProfilePlaceholder from "../../assets/profile-placeholder.png";
import ProfilePlaceholder from "../../assets/profile-pic-placeholder.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { useUser } from "../../UserContext";
import { toast, ToastContainer } from "react-toastify";

import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faGoogle,
  // Add other social media icons as needed
} from "@fortawesome/free-brands-svg-icons";

const SocialMediaLinks = ({ socialMedia }) => {
  // Define an object mapping social media names to their respective icons
  const socialIcons = {
    facebook: faFacebook,
    twitter: faTwitter,
    instagram: faInstagram,
    linkedin: faLinkedin,
    website: faGlobe,
    // Add other mappings as needed
  };
  console.log(socialMedia);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
      }}
    >
      {Object.keys(socialMedia).map((key) => {
        if (socialMedia[key]) {
          return (
            <a
              key={key}
              href={socialMedia[key]}
              target="_blank"
              style={{
                margin: "15px",
              }}
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={socialIcons[key]} size="2x" />
            </a>
          );
        }
        return null;
      })}
    </div>
  );
};

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
}

function NotaryProfile() {
  const { notaryId } = useParams();
  const { user, endpoint } = useUser();
  // Sample data for a notary

  const [notary, setNotary] = useState(null);

  useEffect(() => {
    const fetchNotaryProfile = async () => {
      try {
        const response = await fetch(
          `${endpoint}/api/notaries/notary-id/${notaryId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch notary profile");
        }
        const data = await response.json();
        console.log(data);
        setNotary(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchNotaryProfile();
  }, [notaryId]);

  if (!notary) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <TopNavBar />
      <Container className="profile-container">
        <Row>
          <Col md="4" sm="12" className="profile-card-container">
            <Card className="profile-card">
              {/* If there is no banner image, don't show the banner */}
              {notary.banner_img ? (
                <img
                  src={notary.banner_img}
                  alt="Banner"
                  className="banner-img"
                />
              ) : (
                <div className="banner-img"></div>
              )}
              <CardBody className="text-center">
                <img
                  src={notary.profile_pic || ProfilePlaceholder}
                  alt="Profile"
                  className="profile-pic"
                />
                <h5>{notary.name}</h5>
                <p className="text-muted">{notary.business_name || ""}</p>
                {notary.user_id && (
                  <p>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="me-1"
                      style={{
                        color: "#665942",
                      }}
                    />
                    Verified by Coffee Shop Notaries
                  </p>
                )}
                {!notary.user_id && (
                  <p>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{
                        color: "black",
                      }}
                    />{" "}
                    Not verified
                  </p>
                )}
                <p>{notary.bio}</p>
                {notary.zip_codes && (
                  <div
                    className="zip-codes"
                    style={{
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      marginTop: "10px",
                      margin: "auto",
                      color: "#495057",
                      width: "80%",
                      marginBottom: "20px",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "left",
                      }}
                    >
                      Zip Codes
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        gap: "10px",
                        marginTop: "5px",
                      }}
                      title={notary.zip_codes?.join(", ")} // Tooltip showing all zip codes
                    >
                      {notary.zip_codes?.map((zip, index) => (
                        <span key={index} className="zip-code">
                          {zip}
                          {index < notary.zip_codes.length - 1 ? ", " : ""}
                        </span>
                      ))}
                    </div>
                  </div>
                )}

                <div className="services-pills">
                  {notary.services?.map((service, index) => (
                    <Button
                      key={index}
                      color="primary"
                      size="sm"
                      className="me-1"
                    >
                      {service}
                    </Button>
                  ))}
                </div>
                {notary.languages && (
                  <p
                    style={{
                      marginTop: "12px",
                      marginBottom: "12px",
                      fontSize: "14px",
                      color: "#495057",
                    }}
                  >
                    Languages: {notary.languages}
                  </p>
                )}

                <SocialMediaLinks
                  socialMedia={{
                    facebook: notary.facebook_link,
                    twitter: notary.twitter_link,
                    instagram: notary.instagram_link,
                    linkedin: notary.linkedin_link,
                    website: notary.website_link,
                  }}
                />
                <div
                  style={{
                    textAlign: "left",
                    width: "80%",
                    margin: "auto",
                    marginBottom: 0,
                  }}
                >
                  Profile Link
                </div>
                <div
                  className="profile-link-container"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                    marginTop: "0px",
                    width: "80%",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <Input
                    type="text"
                    value={`https://coffeeshopnotaries.com/notaries/${notary.id}`}
                    className="profile-link"
                    style={{
                      padding: "7px",

                      fontColor: "#495057",
                      backgroundColor: "#F3F4F6FF",
                      borderRadius: "5px",
                      fontSize: 12,
                      border: "none",
                      color: "#495057",
                    }}
                    disabled
                    readOnly
                  />
                  <FontAwesomeIcon
                    icon={faCopy}
                    size="1x"
                    style={{
                      cursor: "pointer",
                      color: "#0C6DFD",
                      alignSelf: "center",
                      justifySelf: "center",
                      marginTop: "10px",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `https://coffeeshopnotaries.com/notaries/${notary.id}`
                      );
                      toast.success("Profile link copied to clipboard");
                    }}
                  />
                </div>
                <Button
                  style={{
                    background: "#665942",
                    color: "white",
                    border: 0,
                  }}
                  className="mt-3 contact-button"
                  onClick={() => {
                    const subject = encodeURIComponent(
                      "Inquiry from Coffee Shop Notaries"
                    );
                    const body = encodeURIComponent(
                      `Hello ${notary.name},\n\nI found your profile on Coffee Shop Notaries and would like to inquire about your services.\n\nThank you.`
                    );
                    window.location.href = `mailto:${notary.email}?subject=${subject}&body=${body}`;
                  }}
                >
                  <FontAwesomeIcon icon={faEnvelope} /> Email
                </Button>
                <br />
                <Button
                  style={{
                    background: "#665942",
                    color: "white",
                    border: 0,
                  }}
                  className="mt-3 contact-button"
                  onClick={() => {
                    const phoneNumber = notary.phone_number;
                    const message = encodeURIComponent(
                      "Hello, I found your profile on Coffee Shop Notaries and would like to inquire about your services."
                    );
                    window.open(`sms:${phoneNumber}?body=${message}`);
                  }}
                >
                  <FontAwesomeIcon icon={faPhone} /> Text
                </Button>
                <br />
                {notary.google_my_business_link && (
                  <Button
                    style={{
                      background: "#665942",
                      color: "white",
                      border: 0,
                    }}
                    className="mt-3 contact-button"
                    onClick={() => {
                      window.open(notary.google_my_business_link);
                    }}
                  >
                    <FontAwesomeIcon icon={faGoogle} /> Leave a Review
                  </Button>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col md="8" sm="12" className="info-container">
            {notary.video_link && (
              <Card className="info-card">
                <CardBody>
                  <h5>Introduction</h5>
                  <div className="video-container">
                    <iframe
                      width="100%"
                      height="350px"
                      src={
                        "https://www.youtube.com/embed/" +
                        getId(notary.video_link)
                      }
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </CardBody>
              </Card>
            )}
            {notary.description && (
              <Card className="info-card">
                <CardBody>
                  <h5>About</h5>
                  <p>
                    {notary.description.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </CardBody>
              </Card>
            )}
            {notary.services && (
              <Card className="info-card">
                <CardBody>
                  <h5>Services</h5>
                  <Row>
                    {notary.services?.map((service, index) => (
                      <Col key={index} md="4" className="mb-3">
                        <Card className="service-card">
                          <CardBody
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              color: "green",
                              padding: "10px",
                            }}
                          >
                            <FontAwesomeIcon icon={faCheck} size="2x" />
                            <p>{service}</p>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </CardBody>
              </Card>
            )}
            {notary.certifications && (
              <Card className="info-card mt-3">
                <CardBody>
                  <h5>Certifications</h5>
                  <Row>
                    {notary.certifications?.map((certification, index) => (
                      <Col key={index} md="4" className="mb-3">
                        <Card className="service-card">
                          <CardBody
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              color: "green",
                              padding: "10px",
                            }}
                          >
                            <FontAwesomeIcon icon={faCheckCircle} size="2x" />
                            <p>{certification}</p>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </CardBody>
              </Card>
            )}
            {notary.education && notary.education.length > 0 && (
              <Card className="info-card mt-3">
                <CardBody>
                  <h5>Education</h5>
                  <p>
                    {notary.education?.map((education) => {
                      return (
                        <p>
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="me-1"
                            style={{
                              background: "none",
                            }}
                          />
                          {education}
                        </p>
                      );
                    })}
                  </p>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
}

export default NotaryProfile;

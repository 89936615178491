import React from "react";
import { Row, Col } from "reactstrap";
import "./FeaturesList.css";
import { faHeart, faPhone, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function FeaturesList() {
  const features = [
    {
      id: 1,
      icon: faSearch,
      title: "Search for Notaries Nearby",
      subtext: "Find notaries in your area using location or zipcode search.",
    },
    {
      id: 2,
      icon: faPhone,
      title: "Contact a Notary of Your Choice",
      subtext:
        "Easily get in touch with a notary by going through their profile.",
    },
    {
      id: 3,
      icon: faHeart,
      title: "Get To Know Your Notaries",
      subtext: "Feel free to read about notaries, watch their intro video and their services before contacting.",
    },
  ];

  return (
    <div className="features-list">
      {features.map((feature) => (
        <Col key={feature.id} md="4" className="feature-item">
          <FontAwesomeIcon
            icon={feature.icon}
            size="2x"
            className="feature-icon"
          />
          <h5 className="feature-title">{feature.title}</h5>
          <p className="feature-subtext">{feature.subtext}</p>
        </Col>
      ))}
    </div>
  );
}

export default FeaturesList;

import React, { createContext, useState, useContext } from 'react';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);
const endpoints = {
  production : 'https://coffee-shop-notaries-v2-4a4c4d6e435d.herokuapp.com',
  development: 'http://localhost:5002',
}
export const UserProvider = ({ children }) => {
  const [endpoint, setEndpoint] = useState(endpoints.production);
  const [user, setUser] = useState(() => {
    const storedUserData = localStorage.getItem('user');
    return storedUserData ? JSON.parse(storedUserData) : null;
  });

  return (
    <UserContext.Provider value={{ user, setUser, endpoint  }}>
      {children}
    </UserContext.Provider>
  );
};

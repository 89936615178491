import React, { useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';

function FAQs(props) {
  const [open, setOpen] = useState('1');
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  
  return (
    <div>
      <h3 style={{
        textAlign: "center",
      }}>Frequently Asked Questions</h3>
      <Accordion open={open} toggle={toggle}>
        <AccordionItem>
          <AccordionHeader targetId="1">What is the Coffee Shop Notary Platform?</AccordionHeader>
          <AccordionBody accordionId="1">
            <strong>The Coffee Shop Notary Platform</strong> is a quality notary database that allows users to search for notaries using location or zipcode search. It's designed to connect individuals with notaries in a convenient and efficient manner.
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="2">How do I search for a notary?</AccordionHeader>
          <AccordionBody accordionId="2">
            <strong>To search for a notary,</strong> simply enter your location or zipcode in the search bar on the platform. The system will then display a list of available notaries in your area along with their contact information.
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="3">Can I book an appointment with a notary through the platform?</AccordionHeader>
          <AccordionBody accordionId="3">
            <strong>Yes,</strong> you can book an appointment with a notary directly through the platform. Once you've found a notary that meets your requirements, you can contact them to schedule an appointment at a convenient time and place.
          </AccordionBody>
        </AccordionItem>
        {/* Add more FAQ items as needed */}
      </Accordion>
    </div>
  );
}

export default FAQs;

import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Progress,
} from "reactstrap";
import "./AccountSetup.css";
import TopNavBar from "../TopNavBar/TopNavBar";
import { toast, ToastContainer } from "react-toastify";
import AccountSetupImg from "../../assets/account-setup.png";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../UserContext";
import Select from "react-select";

function AccountSetup() {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const { user, endpoint } = useUser(); // Access the user data
  const servicesOptions = [
    "Apostille",
    "Boots on the Ground",
    "Comercial Signings",
    "Document Preparation",
    "Field Inspector",
    "General Notary Work",
    "HELOC Signing",
    "Investor Signings",
    "Loan Modification",
    "Permit Runner",
    "Refinance Signing",
    "Reverse Mortgage",
    "RON (Remote Online Notarization)",
    "Witness Only",
  ];
  const [formData, setFormData] = useState({
    fullName: "",
    services: [],
    // Add more fields as needed
  });
  const states = [
    { name: "Alabama", abbreviation: "AL" },
    { name: "Alaska", abbreviation: "AK" },
    { name: "Arizona", abbreviation: "AZ" },
    { name: "Arkansas", abbreviation: "AR" },
    { name: "California", abbreviation: "CA" },
    { name: "Colorado", abbreviation: "CO" },
    { name: "Connecticut", abbreviation: "CT" },
    { name: "Delaware", abbreviation: "DE" },
    { name: "Florida", abbreviation: "FL" },
    { name: "Georgia", abbreviation: "GA" },
    { name: "Hawaii", abbreviation: "HI" },
    { name: "Idaho", abbreviation: "ID" },
    { name: "Illinois", abbreviation: "IL" },
    { name: "Indiana", abbreviation: "IN" },
    { name: "Iowa", abbreviation: "IA" },
    { name: "Kansas", abbreviation: "KS" },
    { name: "Kentucky", abbreviation: "KY" },
    { name: "Louisiana", abbreviation: "LA" },
    { name: "Maine", abbreviation: "ME" },
    { name: "Maryland", abbreviation: "MD" },
    { name: "Massachusetts", abbreviation: "MA" },
    { name: "Michigan", abbreviation: "MI" },
    { name: "Minnesota", abbreviation: "MN" },
    { name: "Mississippi", abbreviation: "MS" },
    { name: "Missouri", abbreviation: "MO" },
    { name: "Montana", abbreviation: "MT" },
    { name: "Nebraska", abbreviation: "NE" },
    { name: "Nevada", abbreviation: "NV" },
    { name: "New Hampshire", abbreviation: "NH" },
    { name: "New Jersey", abbreviation: "NJ" },
    { name: "New Mexico", abbreviation: "NM" },
    { name: "New York", abbreviation: "NY" },
    { name: "North Carolina", abbreviation: "NC" },
    { name: "North Dakota", abbreviation: "ND" },
    { name: "Ohio", abbreviation: "OH" },
    { name: "Oklahoma", abbreviation: "OK" },
    { name: "Oregon", abbreviation: "OR" },
    { name: "Pennsylvania", abbreviation: "PA" },
    { name: "Rhode Island", abbreviation: "RI" },
    { name: "South Carolina", abbreviation: "SC" },
    { name: "South Dakota", abbreviation: "SD" },
    { name: "Tennessee", abbreviation: "TN" },
    { name: "Texas", abbreviation: "TX" },
    { name: "Utah", abbreviation: "UT" },
    { name: "Vermont", abbreviation: "VT" },
    { name: "Virginia", abbreviation: "VA" },
    { name: "Washington", abbreviation: "WA" },
    { name: "West Virginia", abbreviation: "WV" },
    { name: "Wisconsin", abbreviation: "WI" },
    { name: "Wyoming", abbreviation: "WY" },
  ];

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setFormData({ ...formData, services: [...formData.services, value] });
    } else {
      setFormData({
        ...formData,
        services: formData.services.filter((service) => service !== value),
      });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${endpoint}/api/notaries/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          user_id: user.id,
          email: user.email,
        }), // Include user_id in the request body
      });

      if (!response.ok) {
        toast.error(
          "Sorry, we couldnt create your notary profile, please try again later."
        );
        throw new Error("Failed to update notary profile");
      }

      toast.success("Account setup complete!");
      setTimeout(() => {
        navigate("/account");
      }, 3000);
    } catch (error) {
      console.error(error);
      toast.error("Error updating profile");
    }
  };

  return (
    <>
      <TopNavBar />
      <br />
      <br />
      <br />
      <br />
      <Container className="account-setup">
        <Row>
          <Col
            md="6"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Progress value={(step / 5) * 100} />
            <br />
            <br />
            {step === 1 && (
              <>
                <h2 className="bold-title">What is your full name?</h2>
                <p className="light-subtitle">
                  Please enter your full name as it appears on your notary
                  commission.
                </p>
                <Form>
                  <FormGroup>
                    <Label for="fullName">Full Name</Label>
                    <Input
                      type="text"
                      name="fullName"
                      id="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <Button
                    style={{
                      background: "#665942",
                      color: "white",
                      border: 0,
                    }}
                    onClick={handleNextStep}
                  >
                    Next
                  </Button>
                </Form>
              </>
            )}
            {step === 2 && (
              <>
                <h2 className="bold-title">What services do you offer?</h2>
                <Form>
                  <FormGroup>
                    <Label for="services">Services</Label>
                    <div>
                      {servicesOptions.map((service) => (
                        <div key={service}>
                          <Input
                            type="checkbox"
                            name="services"
                            value={service}
                            checked={formData.services.includes(service)}
                            onChange={handleCheckboxChange}
                          />{" "}
                          {service}
                        </div>
                      ))}
                    </div>
                  </FormGroup>
                  <Button
                    color="grey"
                    onClick={handlePrevStep}
                  >
                    Back
                  </Button>
                  <Button
                    style={{
                      background: "#665942",
                      color: "white",
                      border: 0,
                    }}
                    onClick={handleNextStep}
                  >
                    Next
                  </Button>
                </Form>
              </>
            )}
            {step === 3 && (
              <>
                <h2 className="bold-title">What city and state do you service in?</h2>
                <p className="light-subtitle">
                  Just a few more details to complete your profile. Please enter
                  the city and state where you are located.
                </p>

                <Form>
                  {/* Add form groups for location, services offered, languages, phone number, business name */}
                  <FormGroup>
                    <Label for="location">City</Label>
                    <Input
                      type="text"
                      name="city"
                      id="city"
                      value={formData.city}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="state">State</Label>
                    <Input
                      type="select"
                      name="state"
                      id="state"
                      value={formData.state}
                      onChange={handleChange}
                    >
                      <option value="">Select a state</option>
                      {states.map((state) => (
                        <option key={state.abbreviation} value={state.name}>
                          {state.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  {/* Add more form groups as needed */}
                  <Button color="grey" onClick={handlePrevStep}>
                    Back
                  </Button>{" "}
                  <Button
                    style={{
                      background: "#665942",
                      color: "white",
                      border: 0,
                    }}
                    onClick={handleNextStep}
                  >
                    Next
                  </Button>
                </Form>
              </>
            )}
            {step === 4 && (
              <>
                <h2 className="bold-title">Almost Done! What zip codes do you cover? (optional)</h2>
                <p className="light-subtitle">
                  Enter zip codes separated by spaces or commas.<br/>
                  Unsure of the zip codes you cover? Use this <a href="https://tools.usps.com/zip-code-lookup.htm" target="_blank">Zipcode Lookup Tool</a>
                </p>
                <Form>
                  <FormGroup>
                    <Label for="zipCodes">Zip Codes (optional)</Label>
                    <Input
                      type="text"
                      name="zipCodes"
                      id="zipCodes"
                      value={formData.zipCodes}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <Button color="grey" onClick={handlePrevStep}>
                    Back
                  </Button>

                  <Button
                    style={{
                      background: "#665942",
                      color: "white",
                      border: 0,
                    }}
                    onClick={handleSubmit}
                  >
                    {formData.zipCodes ? "Submit" : "Skip & Submit"}
                  </Button>
                </Form>
              </>
            )}
          </Col>
          <Col md="6">
            <img
              src={AccountSetupImg}
              style={{
                width: "100%",
                height: "auto",
              }}
              alt="Account Setup"
            />
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}

export default AccountSetup;

import React from "react";
import { useUser } from "../../UserContext"; // Import useUser hook
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  NavbarToggler,
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
// import profilePlaceholder from "../../assets/profile-placeholder.png";
import profilePlaceholder from "../../assets/profile-pic-placeholder.png";
import "./TopNavBar.css";
// import logo from "../../assets/coffeeshopnotaries-logo.png";
import logo from "../../assets/logo-black.png";

import { useNavigate } from "react-router-dom"; // Import useNavigate
function TopNavBar() {
  const { user } = useUser(); // Access the user state
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate(); // Use the useNavigate hook
  const handleLogout = () => {
    localStorage.removeItem("user");
    window.location.reload();
  };

  return (
    <Navbar dark expand="md" style={{ height: "60px", zIndex: 1000 , backgroundColor: "#665A42"}}>
      <NavbarBrand href="/" >
        <img
          src={logo}
          alt="Coffee Shop Notaries"
          style={{
            width: "80px",
            height: "auto",
            marginLeft: "10px",
            fontWeight: 650,
          }}
        />
        <span
          style={{
            fontSize: "1rem",
            marginLeft: "10px",
            color: "white",
            fontWeight: 450,
          }}
        >
          Coffee Shop Notaries
        </span>
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar style={{
        color: "white"
      }}>
        <Nav className="ms-auto" navbar style={{
          zIndex: 1000,
          
        }}>
          {" "}
          {/* Updated class here */}
          <NavItem
            style={{
              textAlign: "right",
            }}
          >
            <NavLink href="/">Home</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/resources">Resources</NavLink>
          </NavItem>
          <NavItem
            style={{
              textAlign: "right",
            }}
          >
            {" "}
            <NavLink href="/contact">Contact</NavLink>
          </NavItem>
          {user ? (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} style={{
              textAlign: "right",
            
            }}>
              <DropdownToggle tag="span">
                <img
                  src={user.profile_pic || profilePlaceholder}
                  alt="Profile"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    cursor: "pointer",
                    border: "1px solid white",
                  }}
                />
              </DropdownToggle>
              <DropdownMenu end style={{}}>
                <DropdownItem header>{user.email}</DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() => {
                    navigate("/account");
                  }}
                >
                  Account
                </DropdownItem>
                <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          ) : (
            <NavItem style={{
              textAlign: "right",
            }}>
              <Button color="white" href="/login" style={{
                background: "#D6C3A3",
                color: "#665A42",
                fontWeight: 600,
                border: 0,
              }}>
                Login
              </Button>
            </NavItem>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default TopNavBar;

import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import TopNavBar from "../TopNavBar/TopNavBar";
import { ToastContainer, toast } from "react-toastify";
import { useUser } from "../../UserContext";

function ResetPasswordRequest() {
  const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState("");
  const { endpoint } = useUser();

  const handleResetRequest = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${endpoint}/api/auth/reset-password-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();
      if (response.status === 200) {
        setFeedback("Reset password link sent to your email.");
        toast.success("Reset password link sent to your email.");
      } else {
        throw new Error(
          data.message || "An error occurred during reset password request."
        );
      }
    } catch (error) {
      setFeedback(error.message);
      toast.error(error.message);
    }
  };

  return (
    <>
      <TopNavBar />
      <Container className="mt-5">
        <Row>
          <Col md="6" className="offset-md-3">
            <h2>Reset Password</h2>
            <Form onSubmit={handleResetRequest}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
              <Button
                style={{
                  background: "#665942",
                  color: "white",
                  border: 0,
                }}
              >
                Send Reset Link
              </Button>
            </Form>
            {feedback && <div className="feedback">{feedback}</div>}
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}

export default ResetPasswordRequest;

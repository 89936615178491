import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";

// Example components for different routes
import Home from "./components/Home/Home.js";
import About from "./components/About/About.js";
import NotFound from "./components/NotFound/NotFound.js";
import SearchLandingPage from "./components/SearchLandingPage/SearchLandingPage";
import "bootstrap/dist/css/bootstrap.min.css";
import SearchResults from "./components/SearchResults/SearchResults";
import NotaryProfile from "./components/NotaryProfile/NotaryProfile";
import Login from "./components/Login/Login.js";
import Signup from "./components/Login/Signup";
import { UserProvider } from "./UserContext"; // Import UserProvider
import 'react-toastify/dist/ReactToastify.css';
import AccountSetup from "./components/AccountSetup/AccountSetup";
import Account from "./components/Account/Account";
import Contact from "./components/Contact/Contact";
import ResetPasswordRequest from "./components/ResetPasswordRequest/ResetPasswordRequest";
import ResetPasswordPage from "./components/ResetPasswordPage/ResetPasswordPage";
import ResourcesPage from "./components/ResourcesPage/ResourcesPage";

function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<SearchLandingPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/search/:query" element={<SearchResults />} />
          <Route path="/notaries/:notaryId" element={<NotaryProfile />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/resources" element={<ResourcesPage />} />
          <Route path="/account" element={<Account />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/reset-password-request" element={<ResetPasswordRequest />} />
          <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
          <Route path="/account-setup" element={<AccountSetup />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;

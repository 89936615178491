import React, { useState, useEffect } from "react";
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import "./Account.css";
import TopNavBar from "../TopNavBar/TopNavBar";
import { useUser } from "../../UserContext"; // Adjust the path as needed
import ResetPassword from "../ResetPassword/ResetPassword";
import EditNotaryProfile from "./EditNotaryProfile";
import { useNavigate } from "react-router-dom";

function Account() {
  const [activeTab, setActiveTab] = useState("account");
  const { user } = useUser(); // Access the user state
  const navigate = useNavigate();
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else {
      console.log(user);
    }
  }, [user]);
  if (!user)
    return (
      <>
        <TopNavBar />
        <Container>
          <Row>
            <Col>
              <h1>Sorry, please log in to view this page</h1>
            </Col>
          </Row>
        </Container>
      </>
    );
  return (
    <>
      <TopNavBar />
      <br />
      <br />
      <Container className="account-page">
        <Row>
          <Col>
            <h1>Account</h1>
          </Col>
        </Row>
        <Row>
          <Col md="3">
            <Nav vertical pills>
              <NavItem>
                <NavLink
                  href="#"
                  active={activeTab === "account"}
                  onClick={() => handleTabClick("account")}
                >
                  Account
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  active={activeTab === "notary-profile"}
                  onClick={() => handleTabClick("notary-profile")}
                >
                  Notary Profile
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  active={activeTab === "reset-password"}
                  onClick={() => handleTabClick("reset-password")}
                >
                  Reset Password
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col md="9">
            {activeTab === "account" && (
              <div>
                {" "}
                <h3>Overview</h3>
                <p>Email: {user.email}</p>
              </div>
            )}
            {activeTab === "reset-password" && <ResetPassword />}
            {activeTab === "notary-profile" && <EditNotaryProfile />}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Account;

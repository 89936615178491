import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
} from "reactstrap";
import "./Login.css";
import { useUser } from "../../UserContext"; // Adjust the path as needed

import TopNavBar from "../TopNavBar/TopNavBar";
import { faCoffee, faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoginGraphic from "../../assets/login-graphic.png";
import { Link, useNavigate } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [feedback, setFeedback] = useState("");
  const { user, setUser, endpoint } = useUser(); // Access the setUser function
  const navigate = useNavigate(); // Access the navigate function

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${endpoint}/api/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      console.log({ data });
      if (response.status === 200) {
        if (response.status === 200) {
          setFeedback("Login successful.");

          const userData = {
            email: data.user.email,
            profilePic: data.user.profile_pic,
            token: data.token,
            id: data.user.id,
          };
          setUser(userData);
          localStorage.setItem("user", JSON.stringify(userData));
        }
      } else {
        throw new Error(data.message || "An error occurred during login.");
      }
    } catch (error) {
      setFeedback(error.message);
    }
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  return (
    <>
      <TopNavBar />
      <Container className="login-ui">
        <Row>
          <Col md="6" className="login-graphic-container">
            <img
              src={LoginGraphic}
              alt="Login Graphic"
              className="login-graphic"
            />
          </Col>
          <Col md="6">
            <div className="login-container">
              <div className="login-header">Login to Coffee Shop Notaries</div>
              <div className="login-subheader">
                Enter your email and password to login
              </div>
              <Form onSubmit={handleLogin}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormGroup>
                <Button
                  style={{
                    background: "#665942",
                    color: "white",
                    border: 0,
                  }}
                >
                  Login
                </Button>
              </Form>
              <br />
              {feedback && <div className="feedback">{feedback}</div>}
              <Link to="/reset-password-request">Forgot password?</Link>
              <div className="signup-option">
                Don't have an account? <a href="/signup">Sign up</a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;

import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Alert,
} from "reactstrap";
import { useUser } from "../../UserContext";
import { ToastContainer, toast } from "react-toastify";
function ResetPassword() {
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { user, endpoint } = useUser();
  const email = user.email;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    try {
      const response = await fetch(
        `${endpoint}/api/auth/reset-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setPassword("");
        setConfirmPassword("");
        toast.success(data.message);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
      setError(error.message);
    }
  };

  return (
    <Container>
      <Row>
        <Col md={{ size: 6 }}>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <h3>Reset Password</h3>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="Enter your new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </FormGroup>
            {password.length >= 8 && (
              <Input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Confirm your new password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            )}
            {password.length < 8 && (
              <Alert color="danger">
                Password must be at least 8 characters
              </Alert>
            )}
            {password.length >= 8 && password !== confirmPassword && (
              <Alert color="danger">Passwords must match</Alert>
            )}
            {password.length >= 8 && password === confirmPassword && (
              <Alert color="success">Password meets requirements</Alert>
            )}

            <Button
              style={{
                background: "#665942",
                color: "white",
                border: 0,
              }}
              disabled={password.length < 8 || password !== confirmPassword}
            >
              Reset Password
            </Button>
          </Form>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
}

export default ResetPassword;

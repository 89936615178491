import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
} from "reactstrap";
import TopNavBar from "../TopNavBar/TopNavBar";
import "./ResourcesPage.css";
const ResourcesPage = () => {
  const resourceGroups = [
    {
      groupName: "Notary Education",
      resources: [
        {
          title: "Free Notary Training",
          link: "https://training.loansigningsystem.com/lizeth-nevada-free-gnw-training/",
        },
        {
          title: "Become A Notary Signing Agent",
          link: "http://loansigningsystem.com/?afmc=2st",
        },
      ],
    },
    {
      groupName: "Investor Resources",
      resources: [
        {
          title: "Become a Gator",
          link: "https://www.pacejmorby.com/grand-re-opening-to-gator-lending-training-register-now56173998?affiliate_id=4240902",
        },
        {
          title: "Join the Subto Community",
          link: "https://subto.typeform.com/darren",
        },
        {
          title: "Join the Multifi Family",
          link: "https://multifi.typeform.com/to/GegubLwL",
        },
        {
          title: "Fund Your Next Deal - Hard Money/DSCR/New Construction",
          link: "https://voyami.alphagatorfunding.com",
        },
      ],
    },
    {
      groupName: "Business Resources",
      resources: [
        {
          title: "Build your Business Credit with Fund&Grow",
          link: "https://www.fundandgrow.com/lizethgalvanwebinar/",
        },
        {
          title:
            "Relay Business Banking. GET $50 WHEN YOU FUND A RELAY ACCOUNT",
          link: "https://lets.bankwithrelay.com/voyami/",
        },
        {
          title: "Get personal credit funding with Guiding Light Partners",
          link: "https://www.prequalifyme.com/pre-qualification?code=galvan&name=Lizeth%20Galvan",
        },
      ],
    },
  ];

  return (
    <>
      <TopNavBar />
      <div className="resources-page">
      <h1 className="resources-header">Resources</h1>

        {resourceGroups.map((group, groupIndex) => (
          <div key={groupIndex} className="resource-group">
            <h3>{group.groupName}</h3>
            {group.resources.map((resource, resourceIndex) => (
              <Card key={resourceIndex} className="resource-card">
                <CardBody>
                  <CardTitle tag="h5">{resource.title}</CardTitle>
                  <Button href={resource.link} target="_blank" color="primary">
                    Learn More
                  </Button>
                </CardBody>
              </Card>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default ResourcesPage;
